karmads.footer = (function() {
    'use strict';

    function getKarmaScript(file) {
        file = file || 'footer';
        let scripts,
            scriptsLength,
            currentScript,
            matchPattern;

        scripts = doc.querySelectorAll('script[src*="karma.mdpcdn.com"]');
        if (file === 'header') {
            matchPattern = /^http(?:s)?:\/\/([^\.]*)\.?karma\.mdpcdn\.com[:9]*\/service\/(js|js-min)\/(karma\.(header|interstitial)\.js)/;
        } else {
            matchPattern = /^http(?:s)?:\/\/([^\.]*)\.?karma\.mdpcdn\.com[:9]*\/service\/(js|js-min)\/(karma\.(footer|core|service)\.js)/;
        }

        scriptsLength = scripts.length;

        while (scriptsLength--) {
            currentScript = scripts[scriptsLength].src;
            if (currentScript.match(matchPattern)) {
                return currentScript.match(matchPattern);
            }
        }

        return null;
    }

    function log(msg, color) {
        color = color || 'green';
        if (console) {
            console.log('%c--KARMA : ' + msg, 'font-size:12px;color: ' + color + ';');
        }
    }

    function header() {
        // TEST: if karma.header.js is not present on the page, request it
        let headerPath,
            footerPath,
            adTestEnvParam = location.search.toLowerCase().match(/adtestenv=(local|dev|test|www)/),
            adTestMinParam = location.search.toLowerCase().match(/adtestmin=(true|false)/),
            env,
            min,
            karmaHeader = doc.createElement('script'),
            node = doc.getElementsByTagName('script')[0];

        if (adTestEnvParam) {
            env = adTestEnvParam[1];
        } else {
            footerPath = getKarmaScript('footer');
            env = (footerPath[1] === '' || footerPath[1] === undefined) ? 'www' : footerPath[1];
        }
        if (adTestMinParam) {
            min = !(adTestMinParam[1] == 'false');
        } else {
            footerPath = footerPath || getKarmaScript('footer');
            if (env === 'local') {
                min = false;
            } else {
                min = footerPath[2] ? (footerPath[2] === 'js-min') : true;
            }
        }

        log('Loading Karma header file from footer');

        headerPath = 'https://'
        + ((env !== 'www') ? env + '.' : '')
        + 'karma.mdpcdn.com' + ((env === 'local') ? ':9999' : '')
        + '/service/js' + (min ? '-min' : '') + '/karma.header.js';

        karmaHeader.src = headerPath;
        node.parentNode.insertBefore(karmaHeader, node);

    }

    function init() {
        if (!window.karmaService && !getKarmaScript('header')) {
            window.karmaService = {vars: {footerReady: true}};
            header();
        } else {
            if (window.karmaService) {
                window.karmaService.vars.footerReady = true;
            } else {
                window.karmaService = {vars: {footerReady: true}};
            }
            if (!window.karmaService.vars.initialized && window.karmaService.vars.ready) {
                window.karmaService.init();
            }
        }
    }

    init();
})();
